@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700;800&family=Titillium+Web:wght@400;700&display=swap');

$dispalyBG : #595252;
$opratorsBG: #FF9E00;
$numberKeys: #827d7b;
$darkBG : #595252;

$base-font-family: 'Titillium Web', 'Roboto Mono', sans-serif;

* {
    box-sizing: border-box;
}
.clearfix {
    clear: both;
}


body {
  font-family: $base-font-family;
  font-weight: 400;
  padding: 0; margin: 0;
  // background-attachment: fixed;
  background: url('/images/ruslan-bardash-g83y6do219w-unsplash.jpg') no-repeat center bottom;
  background-size: cover;
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 414px;
  padding: 20px 20px 80px;
  background-color: rgba(#fff, .9);
  min-height: 100vh;
  box-shadow: 0 0 3px rgba(#000, .15);
}

.dodging-header {
  position: relative;
  height: 50px;
  padding: 0;
  h1 {
    background: url("/images/logo.png") no-repeat center center;
    background-size: 100%;
    text-indent: -999em;
    width: 30px; height: 30px;
    padding: 0; margin: 0;
    line-height: 30px;
  }
  .dodging-score {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: #000;
  }
}

.dodging-footer {
  padding: 15px 0 0;
  position: absolute;
  width: 100%; left: 0;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  bottom: 20px;
  font-size: 10px;
  line-height: 1.2;
  color: #999;
  text-align: center;
  strong {
    color: #333;
  }
  &::before {
    content: '';
    width: 40px;
    height: 1px;
    position: absolute;
    left: 50%; top: 0;
    transform: translateX(-50%);
    background: #ccc;
  }
}



.dodging {
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  .modal {
    z-index: 99;
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(#fff, .75);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 128px 128px;
    display: none;
    opacity: 0;
    transition: all .2s linear;
    &.correct {
      background-image: url("/images/icon-checkmark.png");
    }
    &.incorrect {
      background-image: url("/images/icon-error.png");
    }
    &.show {
      display: block;
      opacity: 1;
    }
  }
}

.dodging-qa {
    padding-left: 50%;
    position: relative;
    border: 1px solid #d5d5d5;
    height: 64px;
    margin: 0 0 10px;
    background: #e5e5e5;
    font-size: 24px;
    line-height: 1;
    .dodging-question {
      position: absolute;
      width: 50%;
      left: 0; top: 0;
      padding: 19px 12px;
      text-align: center;
      background: #fff;
    }
    .dodging-equalto {
      position: absolute;
      width: 36px; height: 36px;
      border-radius: 100%;
      top: 50%; left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #f3a23d;
      text-align: center;
      line-height: 30px;
      font-size: 24px;
    }
    .dodging-answer {
        text-align: right;
        padding: 19px 12px;
        color: #000;
        font-weight: 700;
    }
}



.butttons-row {
  display: flex;
  width: 100%;
  margin: 1px 0 0;
  border-top: 1px solid #efefef;
  &:first-child {
    border: none;
  }
  .button {
    // margin-left: 1px;
    width: calc(100%/3);
    border-left: 1px solid #efefef;
    &:nth-child(1) {
      border: none;
    }
  }
}


.button {
  padding: 0;
  box-sizing: border-box;
  button {
    font-family: $base-font-family;
    background: #fff;
    height: 75px;
    width: 100%;
    border: none;
    font-size: 24px;
    // box-shadow : 0 0 3px rgba(#000, .14);
    transition: background .2s linear;
    &:active {
      box-shadow: none;
      background: #ccc;
      // transform: translateY(4px);
      // box-shadow: 0 5px #666;
      // transform: translateY(4px);
    }
    &.orange {
      color: #6C3946;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 20px;
    }
    &.dark {
      color: #8C605E;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.toggle-wrapper {
  margin: 30px 0;
  .toggle-btn {
    text-transform: uppercase;
    font-size: 12px;
    color: 000;
    padding: 12px 15px 15px;
    letter-spacing: 1px;
    width: 100%;
    font-weight: 700;
    height: 50px;
    text-align: left;
    border: none;
    border-top: 2px solid #000;
    position: relative;
		cursor: pointer;
    &::before, &::after {
      content: '';
      position: absolute;
      background: #000;
      transition: opacity .2s linear;
    }
    &::before {
      width: 14px; height: 2px;
      right: 10px; top: 50%;
      margin-top: -1px;
      margin-right: 4px;
    }
    
    &::after {
      width: 2px; height: 14px;
      right: 15px; top: 50%;
      margin-right: 5px;
			margin-top: -7px;
    }
  }

  .toggle-content {
    display: none;
    background: #f8f8f8;
    padding: 15px;
    border: 1px solid #efefef;
    p {
      font-size: 14px;
      color: #666;
      margin: 0 0 15px;
    }
  }

  &.expanded {
    .toggle-btn {
      &::after {
        opacity: 0;
      }
    }
    .toggle-content {
      display: block;
    }
  } 
}

.dodging-settings {
  margin: 30px 0;
  .input-wrapper {
    width: calc(30% - 20px);
    float: left;
    position: relative;
    margin-right: 20px;
    label {
      color: #000;
      line-height: 1;
      font-size: 14px;
      text-transform: uppercase;
    }
    input {
      height: 40px;
      font-size: 20px;
      width: 100%;
      padding: 0;
      border: none;
      background: transparent;
      border-bottom: 1px solid #333;
    }
    &__right {
      width: 40%;
      margin: 0;
      padding-top: 8px;
      .btn-reset {
        width: 100%;
        background: #333;
        padding: 20px;
        font-size: 14px;
        color: #fff;
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
  
}

.dodging-history {
  .history-data {        
    .item {
      position: relative;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      &.correct {
        color: green;
      }
      &.incorrect {
        color: red;
      }
      span {
        float: left;
        display: block;
        font-size: 16px;
        font-weight: 400;
        &.note {
          float: right;
          text-align: right;
          font-style: italic;
          font-size: 14px;
        }
        &.answer {
          font-weight: 700;
          margin-left: 15px;
        }
        &.first, &.second {
          width: 30px;
        }
        &.first {
          padding-right: 5px;
          text-align: right;
        }
        &.second {
          padding-left: 5px;
          // text-align: right;
        }
      }
    }
  }
}